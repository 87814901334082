import React from 'react';
import Chart from '../components/Chart'

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {IGlobalContext, MyGlobalContext} from "../App";
import {NavLink, useNavigate} from "react-router-dom";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35rem',
    height: '20rem',
    bgcolor: 'rgb(0 0 0 / 40%)',
    border: 'none !important',
    boxShadow: 0,
    p: 4,
};

export const Home = () => {
    const navigate = useNavigate();
    const {decompStatus, percentage} = React.useContext<IGlobalContext>(MyGlobalContext);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div>
                <img className="center m25vh"
                     src="https://avatars.githubusercontent.com/u/115027903?s=400&amp;u=f8b4d4d6dbde17f8bb7d3a52aa651bc482e1dc36&amp;v=4"
                     alt="Preagonal Logo" title="Preagonal Logo" onClick={handleOpen}/>
                <h1 className="splashtext shadow logo">Preagonal</h1>
            </div>
            {
                (percentage > 0) ?
                    <>
                        <progress onClick={handleOpen} value={percentage} max={100}></progress>
                        <span onClick={handleOpen}
                            className="percentage">{decompStatus?.progressions[0].decomped}/{decompStatus?.progressions[0].all} - {percentage.toFixed(2)}%</span>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            slots={{backdrop: Backdrop}}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <>
                                <Fade in={open}>
                                    <Box sx={style}>
                                        <center><NavLink to="/decomp">DECOMP DETAILS</NavLink></center>
                                        <Chart progressions={decompStatus?.progressions}/>
                                    </Box>
                                </Fade>
                            </>
                        </Modal>
                    </>
                :
                <></>
            }
        </>
    );
};

export default Home;