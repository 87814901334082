import React, {createContext, useEffect} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {DecompDetails} from "./pages/DecompDetails";
import {DecompStatus, Progression} from "./models/Decomp";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DecompClassDetails from "./pages/DecompClassDetails";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export interface IGlobalContext {
    decompStatus?: DecompStatus,
    percentage: number,
    latest: Progression,
}

export const MyGlobalContext = createContext<IGlobalContext>({
    decompStatus: undefined, // set a default value
    percentage: 0,
    latest: {classes: [], date: new Date(), all: 0, decomped: 0, left: 0},
})

const baseUrl = 'https://api.preagonal.net';

export const GetStatus = async (repo: string = 'fourplay'): Promise<DecompStatus> => {
    const [response] = await Promise.all([fetch(baseUrl + '/decomp/' + repo + '/status')]);

    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }

    return response.json();
};

export const GetLatestStatus = async (repo: string = 'fourplay'): Promise<Progression> => {
    const [response] = await Promise.all([fetch(baseUrl + '/decomp/' + repo + '/status/latest')]);

    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }

    return response.json();
};

export const CalculatePercent = (num1: number, num2: number): number => ((num1 / num2) * 100);

export const App = () => {
    // eslint-disable-next-line
    const [decompStatus, setDecompStatus] = React.useState<DecompStatus>();
    const [percentage, setPercentage] = React.useState(0.00);
    const [latest, setLatest] = React.useState<Progression>({classes: [], date: new Date(), all: 0, decomped: 0, left: 0});

    const loadDecompStatus = async () => {
        const status: DecompStatus = await GetStatus();
        const progression: Progression = await GetLatestStatus();

        for (let i =0; i < progression.classes.length; i++) {
            progression.classes[i].percentage = CalculatePercent(progression.classes[i].decomped, progression.classes[i].all);
        }

        setLatest(progression);
        setDecompStatus(status);
        setPercentage(CalculatePercent(status!.progressions[0].decomped, status!.progressions[0].all));
    }

    useEffect(() => {
        loadDecompStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <div id="shade"/>
            <header className="App-header">
                <MyGlobalContext.Provider value={{decompStatus, percentage, latest}}>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/decomp" element={<DecompDetails/>}/>
                        <Route path="/decomp/:slug" element={<DecompClassDetails />} />
                    </Routes>
                </MyGlobalContext.Provider>
            </header>
        </ThemeProvider>
    );
};
/*
<Route path="/products" element={<Products/>}/>
<Route path="/about" element={<About/>}/>
*/
export default App;