import React, {useRef} from "react";
import {HighchartsReact} from 'highcharts-react-official'
import * as Highcharts from 'highcharts';

import theme from 'highcharts/themes/avocado';

import {Progression} from "../models/Decomp";
import {PointOptionsObject} from "highcharts";
import {CalculatePercent} from "../App";
theme(Highcharts);

interface BarProps {
    progressions: Progression[] | undefined;
}

export default function Line(props: BarProps) {

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    // set up chart data
    /*
    const metadata = this.metadata;
    let nonmatchingData = this.parseData(this.csvData[0]);
    let nonmatchingName = this.metadata.series[0].name;
    let nonmatchingVisibility = this.metadata.series[0].visibility;
    */

    let matchingData: Highcharts.PointOptionsObject[] = [];
    let matchingName = "";
    let matchingVisibility = true;
    matchingData = parseData(props.progressions);
    matchingName = 'Total';

    let joined = Array.prototype.concat(matchingData, []);
    joined.sort((a, b) => a["y"] - b["y"]);
    const interval = 1 / 5;
    let max:number|null|undefined = Math.max(interval, Math.ceil(joined.slice(-1)[0].y / interval) * interval);

    if (max < 0.9) {
        max = null;
    }
    let options: Highcharts.Options = {
        chart: {
            type: "line"
        },
        title: { text: '' },
        subtitle: { text: 'Code decompilation progress' },
        tooltip: {
            formatter: function () {
                const data = this.point.options;
                let tooltip = "";

                tooltip += `Date: ${new Date(data.x!).toLocaleString()}<br/>\n`;
                //tooltip += `Commit: ${data["commit"]}<br/>\n`
                tooltip += `Total: ${(+data.y! * 100).toFixed(2)}%<br/>\n`
                tooltip += `-------------------------------------------<br/>\n`;

                /*
                for (let i = 0; i < metadata.subdivisions.length - 1; i += 1) {
                    const text = metadata.subdivisions[i + 1].description
                        .replace("{0}", data["metrics"][i][0])
                        .replace("{1}", data["metrics"][i][1])
                        .replace("{2}", (+data["metrics"][i][2] * 100).toFixed(2));
                    tooltip += `${text}<br/>\n`;
                }
                */

                return tooltip;
            }
        },
        xAxis: {
            title: { text: "Date" },
            type: "datetime"
        },
        yAxis: {
            title: { text: "Completion (%)" },
            labels: { formatter: function () { return `${(+this.value * 100).toFixed(2)}%`; } },
            max: max
        },
        series: [
            /*
            {
                type: "line",
                name: nonmatchingName,
                data: nonmatchingData,
                color: "#DDDF0D",
                visible: nonmatchingVisibility,
                turboThreshold: 5000
            }
            */
        ],
    };

    options.series?.push(
        {
            type: "line",
            name: matchingName,
            data: matchingData,
            color: "#ffc800",
            visible: matchingVisibility,
            turboThreshold: 5000
        }
    );

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartComponentRef}
                {...props}
            />
        </>
    );
}

function parseData(data: Progression[] | undefined): PointOptionsObject[] {
    const seriesData = [];
    let index = 0;
    for (const line of data!) {
        const columns = line;//.split(",");

        const x = +Math.floor(new Date(columns.date).getTime()); // timestamp
        //const commit = columns[2];

        let i = index++;
        const y = +CalculatePercent(columns.decomped, columns.all)/100; // total percentage
        i += 2;

        const metrics: any[] = [];
        /*
        for (const _ in this.metadata.subdivisions.slice(1)) {
            const numerator = +columns[i];
            const denominator = +columns[i + 1];
            const percent = numerator / denominator;

            metrics.push([numerator, denominator, percent]); // params for the description string
            i += 2;
        }
        */

        seriesData.push({
            x: x,
            y: y,
            //commit: commit,
            metrics: metrics
        });
    }

    return seriesData;
}
